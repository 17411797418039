import React from 'react';
import Messages from './sms.js';


function App() {
    return (
        <div className="App">
            <Messages />
        </div>
    );
}

export default App;
