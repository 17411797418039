import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ListView.css';

const Messages = () => {
    const [messages, setMessages] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [messagesPerPage] = useState(5); // Выберите количество сообщений на страницу
    const [search, setSearch] = useState(""); // Добавлено состояние для поиска

    // Логика для обработки клика по описанию
    const handleDescriptionClick = (description) => {
        setShowFullDescription(description);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://localhost:2525/api/showmsg', {
                    headers: {
                        'x-secret-key': process.env.REACT_APP_API_KEY,
                        'Content-Type': 'application/json'
                    }
                });
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    // Функция форматирования даты
    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    // Фильтрация сообщений в соответствии с поисковым запросом
    const filteredMessages = messages.filter(message => 
        message.Name.toLowerCase().includes(search.toLowerCase()) ||
        message.Email.toLowerCase().includes(search.toLowerCase()) ||
        message.Message.toLowerCase().includes(search.toLowerCase())
    );

    // Получение текущих сообщений
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = filteredMessages.slice(indexOfFirstMessage, indexOfLastMessage);

    // Изменение страницы
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Компонент пагинации
    const Pagination = ({ messagesPerPage, totalMessages, paginate }) => {
        const pageNumbers = [];

        for (let i = 1; i <= Math.ceil(totalMessages / messagesPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <nav>
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate(number)} href='!#' className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    };

    return (
        <section>
            <div>
                {/* Поисковое поле */}
                <input
                    type="text"
                    placeholder="Search messages..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <h2>Messages</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Time&Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMessages.map((message) => (
                            <tr key={message.id}>
                                <td>{message.Name}</td>
                                <td>{message.Email}</td>
                                <td>
                                    {message.Message.length > 190
                                        ? (
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                handleDescriptionClick(message.Message);
                                            }}>
                                                {message.Message.substring(0, 250)}...
                                                <span
                                                    className="show-more"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDescriptionClick(message.Message);
                                                    }}
                                                >
                                                    [...]
                                                </span>
                                            </span>
                                        )
                                        : message.Message
                                    }
                                </td>
                                <td>{formatDate(message['Time&Date'])}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Пагинация */}
                <Pagination
                    messagesPerPage={messagesPerPage}
                    totalMessages={filteredMessages.length} // Обновлено для использования длины отфильтрованных сообщений
                    paginate={paginate}
                />
            </div>
            {/* Модальное окно для полного описания */}
            {showFullDescription && (
                <div className="full-description-modal" onClick={() => setShowFullDescription(null)}>
                    <div className="full-description-content">
                        <span className="close" onClick={() => setShowFullDescription(null)}>&times;</span>
                        {showFullDescription}
                    </div>
                </div>
            )}
        </section>
    );
};

export default Messages;
